import * as React from "react";
import { AccountsData } from "../../pages/user.dashboard/services/type";
import { styles } from "./styles";
import { Box, Typography } from "@mui/material";
import ContainedButton from "../button/contained-button";
import OutlinedButton from "../button/outlined-button";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { unlinkBankAccount } from "../../pages/user.dashboard/services/action";
import { unlinkBankAccountFromEntity } from "../../pages/entity.dashboard/services/action";

export interface ConfirmUnlinkBankModalProps {
  open: boolean;
  accountData: AccountsData | undefined;
  entityId?: string | null;
  closeModal: () => void;
}

function ConfirmUnlinkBankModal(props: ConfirmUnlinkBankModalProps) {
  const { closeModal, accountData, open, entityId } = props;
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    console.log(accountData, open);
  }, [accountData, open]);

  const handlesUnlinkBank = () => {
    if (accountData) {
      if (entityId) {
        dispatch(unlinkBankAccountFromEntity(accountData.id, entityId));
      } else {
        dispatch(unlinkBankAccount(accountData.id));
      }
    }
  };

  return (
    <Box sx={styles.modalStyle}>
      <Box>
        <Typography sx={styles.modalHeading}>Unlink Bank</Typography>
        <Typography sx={styles.agreementContent}>
          Are you suer you want to unlink this Bank ?{" "}
        </Typography>
        <Box sx={styles.btnContainer}>
          <ContainedButton
            title={"Unlink"}
            externalStyles={styles.btn}
            size="large"
            fullWidth
            primary
            onClick={handlesUnlinkBank}
          />
          <OutlinedButton
            title="Cancel"
            externalStyles={styles.btn}
            size="large"
            fullWidth
            onClick={() => {
              closeModal();
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default ConfirmUnlinkBankModal;
