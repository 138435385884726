// This file defines a Redux slice for managing the login state.

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { UserDetails, LoginModel } from "./type";

// Initial state for the login slice
const initialState: LoginModel = {
  userDetails: {
    _id: "",
    phoneNo: "",
    email: "",
    name: "",
    countryCode: "",
    otp: "",
    otpTimeStamp: "",
    createdAt: "",
    updatedAt: "",
    accountVerify: true,
    accountId: "",
    accountName: "",
    accountNumber: "",
    contactId: "",
    kycStatus: "",
    ownerId: "",
    frontDocumentId: "",
    backSideDocumentId: "",
    identityContainerId: null,
    businessIdentities: null,
    personalIdentity: "",
    cryptoWalletId: [],
    businessCryptoWalletId: [],
    phone_no: "",
    walletDetails: {
      cryptoWalletId: "",
    },
    role:0,
  },
};

// Create the login slice using createSlice from Redux Toolkit
const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    // Reducer function to set the user details
    setUserDetails: (state: LoginModel, action: PayloadAction<UserDetails>) => {
      state.userDetails = action.payload;
    },
  },
});

// Export the action creators generated by createSlice
export const { setUserDetails } = loginSlice.actions;

// Export the reducer function generated by createSlice
export default loginSlice.reducer;
