import { useEffect } from "react";
import { Box, Tab, Typography } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import styles from "./styles";
import { useDashboardHelper } from "./helper";
import AccoutsTab from "./account-tab";
import BuyEntitiesListing from "./entities-tab";
import WalletDetails from "./wallet-tab";
import { getWalletBalance } from "./services/action";
import ContainedButton from "../../components/button/contained-button";
import { Paths } from "../../common/constants";
/**
 * Defines User Dashboard Page
 * @returns The User Dashboard Component
 */
const UserDahsboard = () => {
  const dispatch = useAppDispatch();
  const { handleTypeChange, type, userDetails, navigate } =
    useDashboardHelper();

  useEffect(() => {
    dispatch(getWalletBalance());
  }, [dispatch]);

  return (
    <Box sx={styles.dashboard_mainContainer}>
      {userDetails && userDetails.name && (
        <Box sx={styles.mainContainer}>
          <Typography sx={styles.mainHeading}>{`Welcome , ${
            userDetails.name || "User"
          }!`}</Typography>
          <Typography sx={styles.subheading}>
            {`Email: ${userDetails.email}`}{" "}
          </Typography>
          <Typography sx={styles.subheading}>
            {`Phone: ${userDetails.phone_no}`}{" "}
          </Typography>
          {userDetails?.role === 1 ? (
            <></>
          ) : (
            <ContainedButton
              title="My Entities"
              onClick={() => navigate(`${Paths.USER_ENTITIES}`)}
              style={{
                padding: "8px 10px",
                position: "relative",
              }}
            />
          )}
        </Box>
      )}
      <TabContext value={type}>
        <Box>
          <TabList onChange={handleTypeChange} sx={styles.tabs}>
            <Tab label="Linked Accounts" value="1" sx={styles.tab} />
            {userDetails?.role !== 1 && 
              <Tab label="Entities" value="2" sx={styles.tab} />
            }
            <Tab label="Wallet" value="3" sx={styles.tab} />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ position: "relative" }}>
          <Box sx={styles.businessIdentityContainer}>
            <AccoutsTab />
          </Box>
        </TabPanel>
        <TabPanel value="2" sx={{ position: "relative" }}>
          <Box sx={styles.businessIdentityContainer}>
            <BuyEntitiesListing />
          </Box>
        </TabPanel>
        <TabPanel value="3" sx={{ position: "relative" }}>
          <Box>
            <WalletDetails />
          </Box>
        </TabPanel>
      </TabContext>
      {/* )} */}
    </Box>
  );
};

export default UserDahsboard;
