import { COLORS } from "../../common/constants";
import { FontFamily } from "../../common/utils";

export const commonTableStyles = {
  headerRow: { border: `1px solid ${COLORS.black}` },
  tableHeader: {
    "& th": {
      padding: "20px",
      textAlign: "center",
      font: "normal normal 600 18px/22px SofiaPro",
      letterSpacing: "1px",
      color: COLORS.black,
      whiteSpace: "nowrap",
      // border: "1px solid black",
      backgroundColor: "rgb(245 247 253)",
    },
    "& th:nth-last-of-type(1)": { textAlign: "center" },
  },
  table: {
    fontFamily: "SofiaPro",
    width: "95%",
    margin: "0 auto",
    border: "5px solid rgb(245 247 253)",
  },
  row: {
    "& td": {
      textAlign: "center",
      padding: "10px 20px",
      font: FontFamily(500, 14, 18),
      color: COLORS.black,
      // border: "1px solid black",
      textTransform: "Capitalize",
      "& a": { textDecoration: "none", cursor: "pointer" },
      "& span": {
        textTransform: "Uppercase",
        color: COLORS.darkGrey,
      },
    },
  },
  sortIcon: { paddingLeft: "3px", height: "11px", cursor: "pointer" },
  pointer: { cursor: "pointer" },
  btnContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    margin: "20px 0 35px",
  },
  yesCancelGrayBtn: {
    font: "normal normal 700 18px/23px SofiaPro",
    width: "130px",
    padding: "13px",
    marginRight: "20px",
  },
  yesCancelthemeBtn: {
    font: "normal normal 700 18px/23px SofiaPro",
    width: "130px",
    padding: "13px",
  },
  noDataCell: {
    font: "normal normal 500 16px/24px SofiaPro",
    textAlign: "center",
    padding: "16px",
  },
  link: { color: COLORS.primary, cursor: "pointer", fontSize: "16px" },
  noData: { display: "flex", justifyContent: "center" },
  statusBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "120px",
    height: "30px",
    borderRadius: "30px",
    flex: "none",
    order: 5,
    flexGrow: 0,
    font: FontFamily(500, 14, 18),
    textAlign: "center",
    margin: "0 auto",
    textTransform: "capitalize",
  },
  successBox: { background: "rgba(1, 188, 130, 0.1)", color: "#01BC82" },
  errorBox: { color: "#E71515", background: "rgba(246, 36, 36, 0.1)" },
  avatar: {
    height: "30px",
    width: "30px",
    marginRight: "5px",
    backgroundColor: COLORS.paginationGrey,
  },
  avatarInitials: {
    backgroundColor: COLORS.paginationGrey,
    height: "30px",
    width: "30px",
    marginRight: "5px",
    font: FontFamily(600, 14, 14),
  },
  tableCell: {
    wordBreak: "break-all",
  },
  span: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "5px",
  },
  ibutton:{
      height: "16px",
      width: "16px",
      alignSelf: "center",
  }
};

export const businessDocuments = {
  table: {
    width: "95%",
    margin: "20px 0",
    border: "5px solid rgb(245 247 253)",
  },
};

export const buyPropertyListing = {
  table: { width: "100%", margin: "20px auto" },
  tableHeader: {
    "& th": {
      font: FontFamily(600, 14, 18),
      textAlign: "center",
      padding: "20px",
    },
  },
  row: {
    "& td": {
      font: FontFamily(500, 14, 18),
      textAlign: "center",
      padding: "10px 20px",
    },
    "& td:nth-of-type(1)": {
      "& a": {
        textDecoration: "none",
      },
    },
  },
};

export const transactionListing = {
  pending: {
    backgroundColor: COLORS.orangeBg,
    color: COLORS.orange,
    border: `1px solid ${COLORS.orangeBg}`,
    padding: "0.5rem",
    borderRadius: "10px",
  },

  completed: {
    background: "rgba(1, 188, 130, 0.1)",
    color: "#01BC82",
    padding: "0.5rem",
    borderRadius: "10px",
  },
};
