// This file defines a Redux slice for managing the home state.

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { EntityDashboardModal } from "./type";
import {
  AccountsData,
  Entity,
  ListingParameters,
  TransactionListingTable,
  WalletDetails,
} from "../../user.dashboard/services/type";

// Initial state for the enity Dashboard slice
const initialState: EntityDashboardModal = {
  entityData: null,
  accountsData: [],
  walletDetails: {
    accountId: "",
    entityId: "",
    walletId: "",
    walletBalance: 0,
  },
  showUnlinkBankModal: false,
  transaction: {
    result: [
      {
        id: "",
        sourceWalletId: "",
        target_wallet_id: "",
        amount: "",
        type: 0,
        status: 0,
        user_id: "",
        entity_id: "",
        createdAt: null,
        updatedAt: null,
        user_entity: {
          name: "",
        },
      },
    ],
    page: 1,
    count: 0,
  },
  transactionListingParameters: {
    limit: 10,
    page: 1,
  },
};

// Create the entity slice using createSlice from Redux Toolkit
const EntityDashabordSlice = createSlice({
  name: "entity",
  initialState,
  reducers: {
    setEntityData: (
      state: EntityDashboardModal,
      action: PayloadAction<Entity | null>
    ) => {
      console.log(action.payload);
      state.entityData = action.payload;
    },
    setEnitityAccounts: (
      state: EntityDashboardModal,
      action: PayloadAction<AccountsData[]>
    ) => {
      console.log(action.payload);
      state.accountsData = action.payload;
    },
    setWalletDetailsForEntity: (
      state: EntityDashboardModal,
      action: PayloadAction<WalletDetails>
    ) => {
      state.walletDetails = action.payload;
    },
    setShowUnlinkModal: (
      state: EntityDashboardModal,
      action: PayloadAction<boolean>
    ) => {
      state.showUnlinkBankModal = action.payload;
    },
    setTransactionForEntity: (
      state: EntityDashboardModal,
      action: PayloadAction<TransactionListingTable>
    ) => {
      state.transaction = action.payload;
    },
    setTransactionListing: (
      state: EntityDashboardModal,
      action: PayloadAction<ListingParameters>
    ) => {
      state.transactionListingParameters = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setEntityData,
  setEnitityAccounts,
  setWalletDetailsForEntity,
  setShowUnlinkModal,
  setTransactionForEntity,
  setTransactionListing
} = EntityDashabordSlice.actions;

export default EntityDashabordSlice.reducer;
