import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { RootState } from "../../redux/store";
import { getProfile } from "../login/services/action";

// Helper Component for Dashboard Component
export const useDashboardHelper = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [type, setType] = useState("1");
  const name = localStorage.getItem("name") || "";
  const { userDetails } = useAppSelector((state: RootState) => state.login);
  const handleTypeChange = (_: any, newValue: string) => {
    setType(newValue);
  };

  useEffect(() => {
    dispatch(getProfile());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return {
    name,
    type,
    handleTypeChange,
    navigate,
    dispatch,
    userDetails
  };
};
