import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
import styles from "./styles";
import Cards from "../../../components/card";
import { Fragment } from "react";
import useAllEntitesHelper from "./helper";
import ContainedButton from "../../../components/button/contained-button";

import SimpleModal from "../../../components/modal";
import ConfirmationContainer from "../../../components/confimation";
import BuyEntityModal from "../../../components/modal/buyEntityModal";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { RootState } from "../../../redux/store";
import { Entity } from "../services/type";

/**
 * Defines Buy Property Listing Page
 * @returns The Buy Property Listing Component
 */
const BuyEntitiesListing = () => {
  const {
    loading,
    allEntities,
    openBuyEntityModal,
    entityId,
    handleBuyEntityModalClose,
    handleBuyEntityModalOpen,
  } = useAllEntitesHelper();
  const { walletDetails } = useAppSelector((state: RootState) => state.user);

  return (
    <Cards
      childComp={
        <Fragment>
          {allEntities && !loading && (
            <TableContainer>
              <Table
                sx={{ ...styles.table, marginBottom: "15px" }}
                stickyHeader
                aria-label="customized table"
              >
                <TableHead sx={{ ...styles.tableHeader }}>
                  <TableRow sx={styles.headerRow}>
                    <TableCell>S.No.</TableCell>
                    <TableCell>Entity Name</TableCell>
                    <TableCell>Published on</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allEntities && allEntities.length > 0 ? (
                    allEntities.map((entity: Entity, index: number) => (
                      <TableRow sx={styles.row} key={"entity-row-" + index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{entity.name}</TableCell>
                        <TableCell>
                          {moment(entity.createdAt).format("YYYY-MM-DD")}
                        </TableCell>
                        <TableCell>
                          <ContainedButton
                            title="Buy"
                            onClick={() => handleBuyEntityModalOpen(entity.id)}
                            style={{
                              padding: "8px 10px",
                              position: "relative",
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={14} align="center">
                        <h3>No Records</h3>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <SimpleModal
            isOpen={openBuyEntityModal}
            handleClose={handleBuyEntityModalClose}
            children={
              <ConfirmationContainer>
                <BuyEntityModal
                  closeModal={handleBuyEntityModalClose}
                  entityId={entityId}
                  walletId={walletDetails.walletId}
                  balance={walletDetails.walletBalance}
                />
              </ConfirmationContainer>
            }
          />
        </Fragment>
      }
    />
  );
};

export default BuyEntitiesListing;
