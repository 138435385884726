import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { RootState } from "../../../redux/store";
import { getPlaidAccounts } from "../services/action";
import { setShowUnlinkModal } from "../services/slice";

// Helper Component for Linked accounts data
const useAccountsHelper = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { accountsData, showUnlinkBankModal } = useAppSelector((state: RootState) => state.user);
  const { loading } = useAppSelector((state: RootState) => state.global);

  const closeConfirmModal = () => {
    dispatch(setShowUnlinkModal(false))
  }

  const openConfirmModal = () => {
    dispatch(setShowUnlinkModal(true))
  }


  useEffect(() => {
    dispatch(getPlaidAccounts());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return { navigate, dispatch, accountsData, loading, showUnlinkBankModal, openConfirmModal, closeConfirmModal}
};

export default useAccountsHelper;
