import { Box, Grid, Typography } from "@mui/material";
import styles from "./styles";
import WalletDetailHelper from "./helper";
import SimpleModal from "../../../components/modal";
import ConfirmationContainer from "../../../components/confimation";
import AddFundsModal from "../../../components/modal/addFundsModal";
import TransactionListing from "../../../components/tables/transactionListing";
import Cards from "../../../components/card";
import ContainedButton from "../../../components/button/contained-button";
import PaginationSection from "../../../components/pagination";
import WithdrawFundsModal from "../../../components/modal/withdrawFundsModal";
import { useDashboardHelper } from "../helper";
const WalletDetailsComp = () => {
  const {
    handleCheckBalModalClose,
    handleCheckBalModalOpen,
    handleWithDrawFundsModalOpen,
    handleWithDrawModalClose,
    handlePageChange,
    accountsData,
    showAddFundsModel,
    showWithdrawFundsModel,
    walletDetails,
    transaction,
  } = WalletDetailHelper();

  const { userDetails } = useDashboardHelper();

  return (
    <Box sx={styles.accountSummary_mainContainer}>
      <Grid container spacing={2} direction="row" justifyContent="space-evenly">
        <Grid item xs={6}>
          <Typography sx={styles.heading}>
            {`Total Balance: $ ${walletDetails.walletBalance}`}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          {userDetails && userDetails.role !==1 && 
          <ContainedButton
            title="Add Funds"
            externalStyles={{
              position: "absolute",
              padding: "15px",
              right: "0%",
            }}
            onClick={handleCheckBalModalOpen}
          />
        }
        </Grid>
        {userDetails && userDetails.role ===1? <Grid item xs={3}/>:
        <Grid item xs={3}>
          {walletDetails.walletBalance > 0 ? (
            <ContainedButton
              title="Withdraw Funds"
              externalStyles={{
                position: "absolute",
                padding: "15px",
                right: "10%",
              }}
              disabled={false}
              onClick={handleWithDrawFundsModalOpen}
            />
          ) : (
            <ContainedButton
              title="Withdraw Funds"
              externalStyles={{
                position: "absolute",
                padding: "15px",
                right: "10%",
              }}
              disabled={true}
              onClick={handleWithDrawFundsModalOpen}
            />
          )}
        </Grid>
        }
      </Grid>
      <Cards
        childComp={
          <Box sx={styles.propertyListingContainer}>
            <TransactionListing
              transaction={transaction?.result}
              walletId={walletDetails.walletId}
            />
            <Box style={{ paddingLeft: "3%", paddingRight: "3%" }}>
              <PaginationSection
                listData={transaction}
                handleChangePage={(val: number) => handlePageChange(val)}
              />
            </Box>
          </Box>
        }
      />
      <SimpleModal
        isOpen={showAddFundsModel}
        handleClose={handleCheckBalModalClose}
        children={
          <ConfirmationContainer>
            <AddFundsModal
              closeModal={handleCheckBalModalClose}
              walletId={walletDetails.walletId}
              accountsData={accountsData}
            />
          </ConfirmationContainer>
        }
      />
      <SimpleModal
        isOpen={showWithdrawFundsModel}
        handleClose={handleWithDrawModalClose}
        children={
          <ConfirmationContainer>
            <WithdrawFundsModal
              closeModal={handleWithDrawModalClose}
              walletId={walletDetails.walletId}
              accountsData={accountsData}
              walletData={walletDetails}
            />
          </ConfirmationContainer>
        }
      />
    </Box>
  );
};

export default WalletDetailsComp;
