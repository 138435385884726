import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Tab, Typography } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import styles from "./styles";
import { useEntityDashboardHelper } from "./helper";
import AccoutsTab from "./account-tab";
import WalletDetails from "./wallet-tab";
import ContainedButton from "../../components/button/contained-button";
import { Paths } from "../../common/constants";
import { getEntityDetails } from "./services/action";
import BuyEntitiesListing from "./entities-tab";
import { getWalletBalance } from "../user.dashboard/services/action";
/**
 * Defines Entity Dashboard Page
 * @returns The Entity Dashboard Component
 */
const EntityDashboard = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const { handleTypeChange, type, navigate, entityData } =
    useEntityDashboardHelper();

  useEffect(() => {
    console.log({ params });
    if (params && params.entityId) {
      dispatch(getEntityDetails(params.entityId));
      dispatch(getWalletBalance(params.entityId));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {entityData && entityData.name && (
        <Box sx={styles.dashboard_mainContainer}>
          <ContainedButton
            title="Go to User Dashboard"
            onClick={() => navigate(`${Paths.USER_DASHBOARD}`)}
            style={{
              padding: "8px 10px",
              position: "relative",
            }}
          />
          <Box sx={styles.mainContainer}>
            <Typography sx={styles.mainHeading}>{`${
              entityData.name || "Entity"
            }!`}</Typography>
            <ContainedButton
              title="My Entities"
              onClick={() => navigate(`${Paths.USER_ENTITIES}`)}
              style={{
                padding: "8px 10px",
                position: "relative",
              }}
            />
          </Box>
          <TabContext value={type}>
            <Box>
              <TabList onChange={handleTypeChange} sx={styles.tabs}>
                <Tab label="Linked Accounts" value="1" sx={styles.tab} />
                <Tab label="Entities to Buy" value="2" sx={styles.tab} />
                <Tab label="Entity Wallet" value="3" sx={styles.tab} />
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ position: "relative" }}>
              <Box sx={styles.businessIdentityContainer}>
                <AccoutsTab />
              </Box>
            </TabPanel>
            <TabPanel value="2" sx={{ position: "relative" }}>
              <Box sx={styles.businessIdentityContainer}>
                <BuyEntitiesListing />
              </Box>
            </TabPanel>
            <TabPanel value="3" sx={{ position: "relative" }}>
              <Box>
                <WalletDetails />
              </Box>
            </TabPanel>
          </TabContext>
        </Box>
      )}
    </>
  );
};

export default EntityDashboard;
