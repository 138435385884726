import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { RootState } from "../../redux/store";
import { Entity } from "../user.dashboard/services/type";
import { setEntityData } from "./services/slice";

// Helper Component for Entity Dashboard Component
export const useEntityDashboardHelper = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [type, setType] = useState("1");
  const name = localStorage.getItem("name") || "";
  const { userDetails } = useAppSelector((state: RootState) => state.login);
  const { entityData } = useAppSelector((state: RootState) => state.entity);
  const handleTypeChange = (_: any, newValue: string) => {
    setType(newValue);
  };

  const setSelectedEntity = (entity: Entity | null) => {
    dispatch(setEntityData(entity));
  };

  return {
    name,
    type,
    handleTypeChange,
    navigate,
    dispatch,
    entityData,
    userDetails,
    setSelectedEntity,
  };
};
