// Import required dependencies from external libraries and local files
import Chip from "@mui/material/Chip";

// Define a custom OutlinedButton component
const OutlinedChip = ({ type }: any) => {
  let typeText: string = "";
  switch (type) {
    case 1:
      typeText = "Buy";
      break;
    case 2:
      typeText = "Sell";
      break;
    case 3:
      typeText = "Deposite";
      break;
    case 4:
      typeText = "Withdraw";
      break;
    case 5:
      typeText = "Withdraw Fee";
      break;
    case 6:
      typeText = "Fee";
      break;
    default:
      typeText = "--";
      break;
  }
  return <Chip label={typeText} />;
};

// Export the OutlinedButton component as the default export
export default OutlinedChip;
