import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { RootState } from "../../../redux/store";
import {
  createLinkToken,
} from "../services/action";

// Helper Component for Add Fund Plaid Component
const AddFundPlaidhelper = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [openAddPlaidModal, setOpenAddPlaidModal] = useState(false);

  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const { fundListing, linkToken } = useAppSelector(
    (state: RootState) => state.fund
  );


  const handleOpenModal = () => setOpenPaymentModal(true);

  const handleCloseModal = () => setOpenPaymentModal(false);

  const handleOpenAddModal = () => dispatch(createLinkToken());

  const handleCloseAddModal = () => setOpenAddPlaidModal(false);


  return {
    navigate,
    dispatch,
    id,
    fundListing,
    openPaymentModal,
    setOpenPaymentModal,
    handleOpenModal,
    handleCloseModal,
    openAddPlaidModal,
    setOpenAddPlaidModal,
    handleOpenAddModal,
    handleCloseAddModal,
    linkToken,
  };
};

export default AddFundPlaidhelper;
