import { AxiosResponse } from "axios";
import { Dispatch } from "@reduxjs/toolkit";

import { EndPoint} from "../../../common/constants";
import { getApiCall } from "../../../common/constants/api";
import { apiSuccessCode } from "../../../common/constants/axios.instance";
import { showAlert } from "../../../common/utils/alert";
import { setLoading } from "../../../redux/global.slice";
import { setUserEntities } from "./slice";



// Action function for getting user entities
export const getUserEntities = () => (dispatch: Dispatch<any>) => {
  if (!navigator.onLine) {
    showAlert(2, "No internet connection!");
    return;
  }

  dispatch(setLoading(true));
  getApiCall(
    EndPoint.GetUserEntities,
    "",
    (response: AxiosResponse<any>) => {
      console.log({response})
      const { statusCode, data } = response.data;
      console.log({statusCode})
      if (statusCode === apiSuccessCode.success) {
        console.log("user entities", data);
        dispatch(setUserEntities(data));
      }
      dispatch(setLoading(false));
    },
    (error: any) => {
      dispatch(setLoading(false));
      showAlert(2, error.message);
    }
  );
};


