import { Box } from "@mui/material";
import { Form, Formik } from "formik";
import styles from "./styles";
import Schema from "../../common/constants/schema";
import LoginHelper from "./helper";
import Input from "../../components/formControls/input";
import ContainedButton from "../../components/button/contained-button";
import { onLogin } from "./services/action";

/**
 * Defines Login Page
 * @returns The rendered Component
 */
const Login = () => {
  const { navigate, initialValue, dispatch } = LoginHelper();

  return (
    <Box sx={styles.mainContainer}>
      <Box sx={styles.heading}>Welcome Back!</Box>
      <Formik
        initialValues={initialValue}
        validationSchema={Schema.LoginSchema}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(onLogin(values, setSubmitting, navigate));
        }}
      >
        {({ isValid }) => {
          return (
            <Form>
              <Box sx={styles.fieldContainer}>
                <Input
                  name="email"
                  type="email"
                  label="Email Address"
                  placeholder="Email Address"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                />
                <Input
                  label="Password"
                  placeholder="Password"
                  name="password"
                  fullWidth
                  isPassword
                  isRequired
                  externalStyles={styles.textField}
                />
              </Box>
              <Box sx={styles.btnContainer}>
                <ContainedButton
                  title="Login"
                  disabled={!isValid}
                  externalStyles={styles.btn}
                  fullWidth
                  size="large"
                  type="submit"
                />
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default Login;
