import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { RootState } from "../../../redux/store";
import { GetAllEntities } from "../../user.dashboard/services/action";

// Helper Component for Buy Entities listing Component
const useAllEntitesHelper = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [openBuyEntityModal, setOpenBuyEntityModal] = useState(false);
  const [entityId, setEntityId] = useState<string>("");
  const { allEntities } = useAppSelector((state: RootState) => state.user);
  const { loading } = useAppSelector((state: RootState) => state.global);

  /**
   * This Function is used to change page
   */
  const handlePageChange = (val: any) => { };
  const handleBuyEntityModalOpen = (entityId: string) => {
    setEntityId(entityId)
    setOpenBuyEntityModal(true);
  };
  const handleBuyEntityModalClose = () => {
    setOpenBuyEntityModal(false);
  };
  useEffect(() => {
    dispatch(GetAllEntities());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return { navigate, dispatch, handlePageChange, loading, allEntities, openBuyEntityModal, entityId, handleBuyEntityModalClose, handleBuyEntityModalOpen };
};

export default useAllEntitesHelper;
