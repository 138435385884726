import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { commonTableStyles as styles } from "../styles";
import moment from "moment";
import OutlinedChip from "../../chip/outlinedChip";
import ColoredChip from "../../chip/coloredChip";
import { TransactionHistroy } from "../../../pages/user.dashboard/services/type";
import { IMAGES } from "../../../common/constants";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { RootState } from "../../../redux/store";

/**
 * Component for rendering transaction table.
 *
 * @param {object} data - The data for the table.
 * @returns {JSX.Element} The rendered component.
 *
 */
const TransactionListing = ({
  transaction,
  walletId,
}: {
  transaction: TransactionHistroy[] | null;
  walletId: string;
}) => {
  const getAmountColor = (type: any) => {
    let color: string = "";
    switch (type) {
      case 1:
      case 4:
        color = "red";
        break;
      case 5:
        color = "red";
        break;
      case 2:
      case 3:
        color = "green";
        break;
      default:
        color = "inherit";
        break;
    }
    return color;
  };

  const getCalculatedAmount = (data: any) => {
    if (data && data.fee) {
      return `$ ${(data.amount - data.fee).toFixed(2)}`;
    } else {
      return `$ ${(data.amount - data.fee).toFixed(2)}`;
    }
  };

  const { userDetails } = useAppSelector((state: RootState) => state.login);

  return (
    <TableContainer>
      <Table sx={styles.table}>
        <TableHead sx={{ ...styles.tableHeader }}>
          <TableRow sx={styles.headerRow}>
            <TableCell>Payment ID</TableCell>
            <TableCell>Date Created</TableCell>
            <TableCell>Type</TableCell>
            {/* <TableCell>Source</TableCell>
            <TableCell>Destination</TableCell> */}
            <TableCell>Status</TableCell>
            {/* <TableCell>Asset</TableCell> */}
            <TableCell>Amount</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {transaction && transaction.length > 0 ? (
            transaction.map((data: any) => (
              <TableRow key={data.id} sx={{ ...styles.row }}>
                <TableCell>
                  {/* <Link
                    to={`${Paths.FORTRESS_TRANSACTION_DETAIL}?id=${data.id}`}
                  > */}
                  <Typography sx={{ ...styles.link }}>{data?.id}</Typography>
                  {/* </Link> */}
                </TableCell>
                <TableCell>
                  <Typography>
                    {moment(data?.createdAt).format("YYYY-MM-DD")}
                  </Typography>
                </TableCell>
                {userDetails && userDetails?.role === 1 ? (
                  <TableCell>
                    {userDetails.role === 1 && data.type === 1 ? (
                      <OutlinedChip type={6} />
                    ) : (
                      <OutlinedChip type={data?.type} />
                    )}
                  </TableCell>
                ) : (
                  <TableCell>
                    {walletId === data.target_wallet_id && data.type === 1 ? (
                      <OutlinedChip type={2} />
                    ) : (
                      <OutlinedChip type={data?.type} />
                    )}
                  </TableCell>
                )}
                {/* <TableCell>
                  <Typography>
                    {data?.source?.custodialAccountId ??
                      data?.source?.externalAccountId}
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography>
                    {data?.destination?.custodialAccountId}
                  </Typography>
                </TableCell> */}

                <TableCell>
                  <ColoredChip status={data?.status} />
                </TableCell>

                {/* <TableCell>
                  <Typography>{data?.currency}</Typography>
                </TableCell> */}
                {userDetails && userDetails.role === 1 ? (
                  <TableCell>
                    {data.type === 1 ? (
                      <Typography sx={{ color: "green" }}>
                        {`$ ${data?.fee || "0.00"}`}
                      </Typography>
                    ) : (
                      <Typography sx={{ color: getAmountColor(data.type) }}>
                        {getAmountColor(data.type) === "red" ? "- " : ""}
                        {`$ ${data?.amount || "0.00"}`}
                      </Typography>
                    )}
                  </TableCell>
                ) : (
                  <TableCell>
                    {walletId === data.target_wallet_id && data.type === 1 ? (
                      <span style={styles.span}>
                        <Typography sx={{ color: "green" }}>
                          {/* {`$ ${data?.amount}`} */}
                          {getCalculatedAmount(data)}
                        </Typography>
                        <Tooltip
                          placement="top"
                          title={`${"Platform Fee: "}${`$ ${
                            data?.fee || "0"
                          }`}`}
                        >
                          <img
                            style={styles.ibutton}
                            alt="i"
                            src={IMAGES.IButton}
                          ></img>
                        </Tooltip>
                      </span>
                    ) : (
                      <Typography sx={{ color: getAmountColor(data.type) }}>
                        {getAmountColor(data.type) === "red" ? "- " : ""}
                        {`$ ${data?.amount}`}
                      </Typography>
                    )}
                  </TableCell>
                )}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={14} align="center">
                <h3>No Records</h3>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TransactionListing;
