// This file defines a Redux slice for managing the home state.

import { createSlice } from "@reduxjs/toolkit";

import type { PayloadAction } from "@reduxjs/toolkit";

import {
  UserModel,
  UserDetails,
  JWTClient,
  WalletDetails,
  AccountsData,
  TransactionListingTable,
  ListingParameters,
  Entity,
} from "./type";

// Initial state for the user slice
const initialState: UserModel = {
  userDetails: {
    _id: "",
    phoneNo: "",
    email: "",
    name: "",
    countryCode: "",
    otp: "",
    otpTimeStamp: "",
    createdAt: "",
    updatedAt: "",
    accountVerify: true,
    accountId: "",
    accountName: "",
    accountNumber: "",
    contactId: "",
    kycStatus: "",
    ownerId: "",
    frontDocumentId: "",
    backSideDocumentId: "",
  },
  jwtToken: {
    authtoken: "",
  },
  accountsData: [],
  walletDetails: {
    accountId: "",
    entityId: "",
    walletId: "",
    walletBalance: 0,
  },
  showUnlinkBankModal: false,
  showAddFundsModel: false,
  showWithdrawFundsModel: false,
  transaction: {
    result: [
      {
        id: "",
        sourceWalletId: "",
        target_wallet_id: "",
        amount: "",
        type: 0,
        status: 0,
        user_id: "",
        entity_id: "",
        createdAt: null,
        updatedAt: null,
        user_entity: {
          name: "",
        },
      },
    ],
    page: 1,
    count: 0,
  },
  transactionListingParameters: {
    limit: 10,
    page: 1,
  },
  allEntities: [],
  addBalanceError: "",
  isCheckingBalance:false
};

// Create the user slice using createSlice from Redux Toolkit
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserDetails: (state: UserModel, action: PayloadAction<UserDetails>) => {
      state.userDetails = action.payload;
    },
    setJWTClient: (state: UserModel, action: PayloadAction<JWTClient>) => {
      state.jwtToken = action.payload;
    },
    setAccounts: (state: UserModel, action: PayloadAction<AccountsData[]>) => {
      console.log(action.payload);
      state.accountsData = action.payload;
    },
    setWalletDetails: (
      state: UserModel,
      action: PayloadAction<WalletDetails>
    ) => {
      state.walletDetails = action.payload;
    },
    setShowUnlinkModal: (state: UserModel, action: PayloadAction<boolean>) => {
      state.showUnlinkBankModal = action.payload;
    },
    setShowAddFundsModal: (state: UserModel, action: PayloadAction<boolean>) => {
      state.showAddFundsModel = action.payload;
    },
    setShowWithdrawFundsModel: (state: UserModel, action: PayloadAction<boolean>) => {
      state.showWithdrawFundsModel = action.payload;
    },
    setAllEntities: (state: UserModel, action: PayloadAction<Entity[]>) => {
      state.allEntities = action.payload;
    },
    setTransaction: (
      state: UserModel,
      action: PayloadAction<TransactionListingTable>
    ) => {
      state.transaction = action.payload;
    },
    setTransactionListing: (
      state: UserModel,
      action: PayloadAction<ListingParameters>
    ) => {
      state.transactionListingParameters = action.payload;
    },
    setAddBalanceError: (
      state: UserModel,
      action: PayloadAction<string>
    ) => {
      state.addBalanceError = action.payload;
    },
    setIsCheckingBalance: (
      state: UserModel,
      action: PayloadAction<boolean>
    ) => {
      state.isCheckingBalance = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setUserDetails,
  setJWTClient,
  setAccounts,
  setWalletDetails,
  setShowUnlinkModal,
  setTransactionListing,
  setTransaction,
  setAllEntities,
  setShowAddFundsModal,
  setShowWithdrawFundsModel,
  setAddBalanceError,
  setIsCheckingBalance
} = userSlice.actions;

export default userSlice.reducer;
