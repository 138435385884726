import { AxiosResponse } from "axios";
import { Dispatch } from "@reduxjs/toolkit";

import { EndPoint } from "../../../common/constants";
import { getApiCall, postApiCall } from "../../../common/constants/api";
import { apiSuccessCode } from "../../../common/constants/axios.instance";
import { showAlert } from "../../../common/utils/alert";
import { setLoading } from "../../../redux/global.slice";
import {
  setEnitityAccounts,
  setEntityData,
  setShowUnlinkModal,
} from "./slice";

// Action function for getting entity details from Id
export const getEntityDetails =
  (entityId: string) => (dispatch: Dispatch<any>) => {
    if (!navigator.onLine) {
      showAlert(2, "No internet connection!");
      return;
    }
    dispatch(setLoading(true));
    getApiCall(
      EndPoint.GetEntityDetails + "?entity_id=" + entityId,
      "",
      (response: AxiosResponse<any>) => {
        console.log({ response });
        const { statusCode, data } = response.data;
        console.log({ statusCode });
        if (statusCode === apiSuccessCode.success) {
          console.log("Entity Details Data", data);
          dispatch(setEntityData(data));
        }
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };

// Action function for getting connected  bank accounts
export const getPlaidAccountsForEntity =
  (entityId: string) => (dispatch: Dispatch<any>) => {
    if (!navigator.onLine) {
      showAlert(2, "No internet connection!");
      return;
    }
    dispatch(setLoading(true));
    getApiCall(
      EndPoint.GetPlaidAccounts + "?entity_id=" + entityId,
      "",
      (response: AxiosResponse<any>) => {
        console.log({ response });
        const { statusCode, data } = response.data;
        console.log({ statusCode });
        if (statusCode === apiSuccessCode.success) {
          console.log("Entity connected Accounts", data.data);
          dispatch(setEnitityAccounts(data.data));
        }
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };

// Action function for getting connected  bank accounts
export const unlinkBankAccountFromEntity =
  (bankId: string, entityId: string) => (dispatch: Dispatch<any>) => {
    if (!navigator.onLine) {
      showAlert(2, "No internet connection!");
      return;
    }
    dispatch(setLoading(true));
    postApiCall(
      EndPoint.UnlinkBankAccount,
      { bankId },
      (response: AxiosResponse<any>) => {
        console.log({ response });
        const { statusCode, message } = response.data;
        console.log({ statusCode });
        if (statusCode === apiSuccessCode.success) {
          dispatch(setShowUnlinkModal(false));
          showAlert(1, message);
          dispatch(getPlaidAccountsForEntity(entityId));
        }
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };

export const addWalletBalance =
  (fundData: any) => (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    postApiCall(
      `${EndPoint.addWalletBalance}`,
      fundData,
      (response: AxiosResponse<any>) => {
        if (response.data.statusCode === apiSuccessCode.success) {
          dispatch(setLoading(false));
          return;
        }
      },
      (error: any) => {
        dispatch(setLoading(false));
        console.log(error);
      }
    );
  };
