import { COLORS } from "../../common/constants";
import { FontFamily } from "../../common/utils";

export const styles = {
  modalStyle: {
    minWidth: "600px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    backgroundColor: COLORS.white,
    padding: "2rem",
  },

  modalContentStyling: {
    display: "flex",
    flexDirection: "column",
    // height: "650px",

    "& iframe": {
      margin: "0 auto",
    },

    "& a": {
      color: COLORS.white,
      textDecoration: "none",
      textTransform: "capitalize",
    },
  },

  certBtn: {
    color: COLORS.white,
    textDecoration: "none",
    textTransform: "capitalize",
    cursor: "pointer",
    borderRadius: "0",
    background: `linear-gradient(30.99deg, ${COLORS.primaryBlue} 0%, ${COLORS.lightBlue} 100%)`,
    fontSize: "18px",
    boxShadow: `0px 10px 14px rgba(0, 0, 0, 0.15)`,
    padding: "0.8rem 1rem",
    margin: "0 1rem",
  },

  formContainer: {
    width: "80%",
    margin: "0 auto",
    display: "grid",
    gridTemplateColumns: "1fr",
    columnGap: "50px",
  },

  textField: {
    margin: "10px 0",

    "& .MuiTypography-h6": {
      textAlign: "left",
      marginBottom: "5px",
    },
  },

  btnContainer: {
    width: "100%%",
    margin: "2rem auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  btn: {
    width: "30%",
    margin: "0 1rem",
    padding: "12px",
    font: "normal normal 700 20px/25px SofiaPro",
  },

  modalHeading: {
    font: FontFamily(700, 25, 30),
    textAlign: "center",
    letterSpacing: "1px",
    paddingBottom: "10px",
  },

  subHeading: {
    font: FontFamily(500, 20, 22),
    textAlign: "center",
    letterSpacing: "1px",
    paddingBottom: "10px",
  },

  selectComp: { width: "47%", marginRight: "20px" },

  timerContainer: {
    position: "absolute",
    top: "5%",
    right: "5%",
    border: `3px solid #E71515`,
    padding: "0.5rem",
    borderRadius: "50%",
    width: "20px",
    height: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  agreementDetailModal: {
    maxWidth: "700px",
    overflow: "hidden",
    overflowX: "auto",
    textAlign: "center",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },

  agreementContent: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "1rem",
  },

  agreementData: {
    marginLeft: "1rem",
    font: FontFamily(500, 16, 20),
  },

  agreementHeading: {
    font: FontFamily(600, 18, 22),
    letterSpacing: "1px",
    whiteSpace: "wrap",
  },
};
