import { Fragment, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import styles from "./styles";
import { useUserEntitesHelper } from "./helper";
import { getUserEntities } from "./services/action";
import Cards from "../../components/card";
import moment from "moment";
import ContainedButton from "../../components/button/contained-button";
import { Paths } from "../../common/constants";
import { setEntityData } from "../entity.dashboard/services/slice";
/**
 * Defines The User Entites Page
 * @returns The User Entites Component
 */
const UserEntities = () => {
  const dispatch = useAppDispatch();
  const { userEntities, loading, navigate } = useUserEntitesHelper();

  useEffect(() => {
    dispatch(getUserEntities());
  }, [dispatch]);

  return (
    <Box sx={styles.dashboard_mainContainer}>
      <ContainedButton
        title="Back to User Dashboard"
        onClick={() => navigate(`${Paths.USER_DASHBOARD}`)}
        style={{
          padding: "8px 10px",
          position: "relative",
        }}
      />
      <Box sx={styles.mainContainer}>
        <Typography sx={styles.mainHeading}>{`Entities`}</Typography>
      </Box>
      <Box>
        <Cards
          childComp={
            <Fragment>
              {userEntities && !loading && (
                <TableContainer>
                  <Table
                    sx={{ ...styles.table, marginBottom: "15px" }}
                    stickyHeader
                    aria-label="customized table"
                  >
                    <TableHead sx={{ ...styles.tableHeader }}>
                      <TableRow sx={styles.headerRow}>
                        <TableCell>S.No.</TableCell>
                        <TableCell>Entity Name</TableCell>
                        <TableCell>Published on</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userEntities && userEntities.length > 0 ? (
                        userEntities.map((entity: any, index: number) => (
                          <TableRow sx={styles.row} key={"entity-row-" + index}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{entity?.name}</TableCell>
                            <TableCell>
                              {moment(entity?.createdAt).format("YYYY-MM-DD")}
                            </TableCell>
                            <TableCell>
                              <ContainedButton
                                title="Dashboard"
                                onClick={() => {
                                  dispatch(setEntityData(entity));
                                  navigate(
                                    Paths.ENTITY_DASHBOARD.replace(
                                      ":entityId",
                                      entity.id
                                    )
                                  );
                                }}
                                style={{
                                  padding: "8px 10px",
                                  position: "relative",
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={14} align="center">
                            <h3>No Records</h3>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Fragment>
          }
        />
      </Box>
    </Box>
  );
};

export default UserEntities;
