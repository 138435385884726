import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import styles from "./styles";
import { Fragment, useEffect, useState } from "react";
import { usePlaidLink } from "react-plaid-link";
import useAccountsHelper from "./helper";
import AddFundPlaidhelper from "../../plaid-link/fund.plaid/helper";
import { setLinkToken } from "../../plaid-link/services/slice";
import { PublicTokenExchange } from "../../plaid-link/services/action";
import Cards from "../../../components/card";
import ContainedButton from "../../../components/button/contained-button";
import ConfirmUnlinkBankModal from "../../../components/modal/confirmUnlinkBankModal";
import SimpleModal from "../../../components/modal";
import { AccountsData } from "../../user.dashboard/services/type";

/**
 * Defines Accounts Tab
 * @returns The rendered Component
 */
const AccoutsTab = () => {
  const {
    accountsData,
    loading,
    showUnlinkBankModal,
    closeConfirmModal,
    openConfirmModal,
    entityData,
  } = useAccountsHelper();

  const [bankData, setBankData] = useState<AccountsData>();

  const { handleOpenAddModal, linkToken, dispatch } = AddFundPlaidhelper();

  const config: Parameters<typeof usePlaidLink>[0] = {
    token: linkToken,
    onSuccess: async (publicToken, metadata) => {
      console.log({ publicToken, metadata });
      dispatch(setLinkToken(""));
      if (entityData) {
        dispatch(PublicTokenExchange(publicToken, metadata, entityData.id));
      }
    },
  };
  const { open: openPlaidLink, ready } = usePlaidLink(config);

  useEffect(() => {
    console.log({ linkToken });
    if (ready && linkToken) {
      openPlaidLink();
    }
  }, [linkToken, ready, openPlaidLink]);

  useEffect(() => {
    console.log(accountsData);
  }, [accountsData]);

  const handleUnlinkBank = (data: AccountsData) => {
    setBankData(data);
    openConfirmModal();
  };

  return (
    <Cards
      childComp={
        <Fragment>
          <ContainedButton
            title="Add Plaid Account"
            onClick={handleOpenAddModal}
          />
          {accountsData.length === 0 && !loading && (
            <Typography sx={styles.heading}>
              No account connected yet
            </Typography>
          )}
          {accountsData.length > 0 && !loading && (
            <TableContainer>
              {accountsData && accountsData.length > 0 ? (
                accountsData.map((bank: any, index: number) => (
                  <Table
                    sx={{ ...styles.table, marginBottom: "15px" }}
                    stickyHeader
                    aria-label="customized table"
                    key={"mainTable" + index}
                  >
                    <TableHead sx={{ ...styles.tableHeader }}>
                      <TableRow sx={styles.headerRow}>
                        <TableCell style={{ textAlign: "left" }}>
                          {index + 1} .{" "}
                          {bank.plaid_account?.institution.name ?? "-"}
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          {" "}
                          <ContainedButton
                            title="unlink Bank"
                            onClick={() => handleUnlinkBank(bank)}
                            style={{ padding: "8px 10px" }}
                          />
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      <TableRow sx={{ ...styles.headerRow, fontStyle: "bold" }}>
                        <TableCell sx={{ ...styles.headerCell, width: "33%" }}>
                          Account Name
                        </TableCell>
                        <TableCell sx={{ ...styles.headerCell }}>
                          Subtype
                        </TableCell>
                        <TableCell sx={{ ...styles.headerCell }}>
                          type
                        </TableCell>
                      </TableRow>
                      {bank.plaid_account?.accounts.length > 0 &&
                        bank.plaid_account.accounts.map(
                          (data: any, dataIndex: number) => (
                            <TableRow
                              key={index + "-" + dataIndex}
                              sx={{ ...styles.row }}
                            >
                              <TableCell>
                                <Typography>{data.name ?? "-"}</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>{data.subtype ?? "-"}</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>{data.type}</Typography>
                              </TableCell>
                            </TableRow>
                          )
                        )}
                    </TableBody>
                  </Table>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={14} align="center">
                    <h3>No Records</h3>
                  </TableCell>
                </TableRow>
              )}
            </TableContainer>
          )}
          <SimpleModal
            isOpen={showUnlinkBankModal}
            handleClose={closeConfirmModal}
            children={
              <ConfirmUnlinkBankModal
                open={showUnlinkBankModal}
                accountData={bankData}
                entityId={entityData ? entityData.id : null}
                closeModal={closeConfirmModal}
              />
            }
          />
        </Fragment>
      }
    />
  );
};

export default AccoutsTab;
