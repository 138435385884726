import axios from "axios";
import { Env } from "./env";

export const API_URL = Env.BASE_URL;
export const apiErrorCode = {
  INVALID_TOKEN: 419,
  ACCOUNT_BLOCKED: 423,
  ACCESS_DENIED: 430,
  SESSION_EXPIRED: 440,
  INTERNAL_ERROR: 500,
  NOT_AUTHORIZED: 403,
  USER_BLOCKED: 401,
  USER_NOT_VERIFIED: 411,
};

export const apiSuccessCode = {
  success: 200,
  success2: 400,
};

/**
 * Configuration for Axios instance.
 */

// Create an instance of Axios
export const $axios = axios.create({
  baseURL: API_URL,
  timeout: 30000,
  headers: { "Content-Type": "application/json", accept: "application/json" },
});

// Add a request interceptor
$axios.interceptors.request.use(
  (config: any) => {
    const token = localStorage.getItem("auth_token");

    // If a token is available, add it to the Authorization header
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);

/**
 * Configuration for Axios instance.
 */

// Create an instance of Axios
export const $axios1 = axios.create({
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
});

// Add a request interceptor
$axios.interceptors.request.use(
  (config: any) => {
    const token = localStorage.getItem("auth_token");
    config.headers.Authorization = "Basic d2F0YW5pOndhdGFuaUAxMjM=";

    // If a token is available, add it to the Authorization header
    if (token) {
      config.headers["access-token"] = token;
    }
    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);
