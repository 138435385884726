import { COLORS } from "../../common/constants";
import { FontFamily } from "../../common/utils";

const styles = {
  dashboard_mainContainer: {
    borderRadius: "6px",
    height: "100%",
    margin: "30px auto 0",
    minHeight: "85vh",
    padding: "15px",
    backgroundColor: COLORS.white,
    boxShadow: ` 0px 4px 32px rgba(0, 0, 0, 0.08)`,
    width: "95%",
  },

  heading: {
    font: FontFamily(700, 24, 22),
    paddingTop: "15px",
    textAlign: "left",
    width: "95%",
    margin: "0 auto 1rem",
  },
  textContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: "1rem",
  },

  textHeading: {
    marginRight: "1rem",
    fontSize: "20px",
    fontWeight: 550,
  },
  mainContainer: {
    height: "100%",
    padding: "1rem 2rem",
    textAlign: "center",
    width: "80%",
    margin: "10px auto",
  },
  mainHeading: {
    font: FontFamily(500, 32, 35),
    paddingBottom: "40px",
    color: COLORS.black,
  },
  subheading: {
    font: FontFamily(500, 20, 22),
    paddingBottom: "20px",
    color: COLORS.black,
  },
  headerRow: { border: `1px solid ${COLORS.black}` },
  tableHeader: {
    "& th": {
      padding: "20px",
      textAlign: "center",
      font: "normal normal 600 18px/22px SofiaPro",
      letterSpacing: "1px",
      color: COLORS.black,
      whiteSpace: "nowrap",
      // border: "1px solid black",
      backgroundColor: "rgb(245 247 253)",
    },
    "& th:nth-last-of-type(1)": { textAlign: "center" },
  },
  table: {
    fontFamily: "SofiaPro",
    width: "95%",
    margin: "0 auto",
    border: "5px solid rgb(245 247 253)",
  },
  row: {
    "& td": {
      textAlign: "center",
      padding: "10px 20px",
      font: FontFamily(500, 14, 18),
      color: COLORS.black,
      textTransform: "Capitalize",
      "& a": { textDecoration: "none", cursor: "pointer" },
      "& span": {
        textTransform: "Uppercase",
        color: COLORS.darkGrey,
      },
    },
  }
};

export default styles;
