import * as React from "react";
import { styles } from "./styles";
import { Box, Typography } from "@mui/material";
import ContainedButton from "../button/contained-button";
import OutlinedButton from "../button/outlined-button";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { buyEntity ,getWalletBalance} from "../../pages/user.dashboard/services/action";

interface PropertyBuyType {
  entityId?: string | null;
  walletId?: string | null;
  balance:any
  closeModal: () => void;
  }

function BuyEntityModal(props: PropertyBuyType) {
  const { closeModal, walletId, entityId ,balance} = props;
  const dispatch = useAppDispatch();


  const handleBuyEntity = () => {
      const dataToSend = {
        source_wallet_id:walletId,
          entity_id: entityId,
          amount:10
        };
        dispatch(buyEntity(dataToSend));
        dispatch(getWalletBalance())
        closeModal();
  };

  return (
    <Box sx={styles.modalStyle}>
      <Box>
      <Typography sx={styles.modalHeading}>Buy Entity</Typography>
        <Typography sx={styles.subHeading}>
         { `Available Balance : $ ${balance}`}
        </Typography>
        <Typography sx={styles.subHeading}>
         { `Entity Price : $10`}
        </Typography>
        <Typography sx={styles.subHeading}>
          Are you sure you want to buy this entity ?
        </Typography>
        <Box sx={styles.btnContainer}>
          <ContainedButton
            title={"Buy"}
            externalStyles={styles.btn}
            size="large"
            fullWidth
            primary
            onClick={handleBuyEntity}
          />
          <OutlinedButton
            title="Cancel"
            externalStyles={styles.btn}
            size="large"
            fullWidth
            onClick={() => {
              closeModal();
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default BuyEntityModal;
