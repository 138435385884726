import { AxiosResponse } from "axios";
import { Dispatch } from "@reduxjs/toolkit";

import { EndPoint } from "../../../common/constants";
import { getApiCall, postApiCall } from "../../../common/constants/api";
import { apiSuccessCode } from "../../../common/constants/axios.instance";
import { showAlert } from "../../../common/utils/alert";
import { setLoading } from "../../../redux/global.slice";
import { RootState } from "../../../redux/store";
import {
  setAccounts,
  setAllEntities,
  setShowUnlinkModal,
  setTransaction,
  setWalletDetails,
  setAddBalanceError,
  setIsCheckingBalance
} from "./slice";
import {
  setTransactionForEntity,
  setWalletDetailsForEntity,
} from "../../entity.dashboard/services/slice";


// Action for getting JWT Token
export const getJwtToken = () => (dispatch: Dispatch<any>) => {


  if (!navigator.onLine) {
    showAlert(2, "No internet connection!");
    return;
  }
  dispatch(setLoading(true));
  getApiCall(
    EndPoint.JwtTokenClient,
    "",
    (response: AxiosResponse<any>) => {
      const { statusCode, data } = response.data;
      if (statusCode === apiSuccessCode.success) {
        localStorage.setItem("auth_token_client", data?.authToken);
      }
      dispatch(setLoading(false));
    },
    (error: any) => {
      dispatch(setLoading(false));
      showAlert(2, error.message);
    }
  );
};

// Action function for getting connected  bank accounts
export const getPlaidAccounts = () => (dispatch: Dispatch<any>) => {
  if (!navigator.onLine) {
    showAlert(2, "No internet connection!");
    return;
  }

  dispatch(setLoading(true));
  getApiCall(
    EndPoint.GetPlaidAccounts,
    "",
    (response: AxiosResponse<any>) => {
      console.log({ response });
      const { statusCode, data } = response.data;
      console.log({ statusCode });
      if (statusCode === apiSuccessCode.success) {
        console.log("data", data.data);
        dispatch(setAccounts(data.data));
      }
      dispatch(setLoading(false));
    },
    (error: any) => {
      dispatch(setLoading(false));
      showAlert(2, error.message);
    }
  );
};

// Action function to get wallet balance
export const getWalletBalance =
  (entity_id?: string) => (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    postApiCall(
      `${EndPoint.GetWalletBalance}`,
      entity_id ? { entity_id } : {},
      (response: AxiosResponse<any>) => {
        const { data } = response.data;
        if (response.data.statusCode === apiSuccessCode.success) {
          const walletData = {
            accountId: data.entity_id,
            entityId: data.entity_id,
            walletId: data.id,
            walletBalance: data.balance,
          };
          if (entity_id) {
            dispatch(walletTransactions(data.id, entity_id))
            dispatch(setWalletDetailsForEntity(walletData));
          } else {
            dispatch(setWalletDetails(walletData));
          }
        }
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        console.log(error.data.err);
      }
    );
  };

// Action function for getting connected  bank accounts
export const unlinkBankAccount =
  (bankId: string) => (dispatch: Dispatch<any>) => {
    if (!navigator.onLine) {
      showAlert(2, "No internet connection!");
      return;
    }
    dispatch(setLoading(true));
    postApiCall(
      EndPoint.UnlinkBankAccount,
      { bankId },
      (response: AxiosResponse<any>) => {
        const { statusCode, message } = response.data;
        console.log({ statusCode });
        if (statusCode === apiSuccessCode.success) {
          dispatch(setShowUnlinkModal(false));
          showAlert(1, message);
          dispatch(getPlaidAccounts());
        }
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };
export const checkBankBalanceBeforeAddFund =
  (amount: string, bankData: any,) => (dispatch: Dispatch<any>) => {
    dispatch(setIsCheckingBalance(true));


    const AccountIdandBankId = bankData.split(" ");
    const bodyParams = {
      account_id: AccountIdandBankId[0],
      bank_id: AccountIdandBankId[1]
    }
    postApiCall(
      `${EndPoint.checkBalance}`,
      bodyParams,
      (response: AxiosResponse<any>) => {
        if (response.data.statusCode === apiSuccessCode.success) {
          const { balance } = response.data.data
          if (amount <= balance) {
            dispatch(setAddBalanceError("no error"))
            dispatch(setIsCheckingBalance(false));
          }
          else {
            dispatch(setAddBalanceError("Insufficient Balance"));
            dispatch(setIsCheckingBalance(false));
          }
        }
      },
      (error: any) => {
        dispatch(setIsCheckingBalance(false));
        return error
      }
    );
  };
export const addWalletBalance =
  (fundData: any) => (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    postApiCall(
      `${EndPoint.addWalletBalance}`,
      fundData,
      (response: AxiosResponse<any>) => {
        if (response.data.statusCode === apiSuccessCode.success) {
          if (fundData.entity_id) {
            dispatch(getWalletBalance(fundData.entity_id));
          } else {
            dispatch(getWalletBalance());
          }
          showAlert(1, response.data.data.message);
          dispatch(setLoading(false));
          dispatch(setAddBalanceError(""))
        }
      },
      (error: any) => {
        showAlert(2, error.data.message);
        dispatch(setLoading(false));
        dispatch(setAddBalanceError(""))
        console.log(error);
      }
    );
  };
export const withdrawWalletBalance =
  (fundData: any) => (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    postApiCall(
      `${EndPoint.withdrawWalletBalance}`,
      fundData,
      (response: AxiosResponse<any>) => {
        if (response.data.statusCode === apiSuccessCode.success) {
          if (fundData.entity_id) {
            dispatch(getWalletBalance(fundData.entity_id));
          } else {
            dispatch(getWalletBalance());
          }
          showAlert(1, "Fund withdrawn successfully.")
          dispatch(setLoading(false));
        }
      },
      (error: any) => {
        showAlert(2, error.data.message)
        dispatch(setLoading(false));
        console.log(error);
      }
    );
  };

export const GetAllEntities = () => (dispatch: Dispatch<any>) => {
  if (!navigator.onLine) {
    showAlert(2, "No internet connection!");
    return;
  }

  dispatch(setLoading(true));
  getApiCall(
    EndPoint.GetAllEntities,
    "",
    (response: AxiosResponse<any>) => {
      console.log({ response });
      const { statusCode, data } = response.data;
      console.log({ statusCode });
      if (statusCode === apiSuccessCode.success) {
        console.log("All entities", data);
        dispatch(setAllEntities(data));
      }
      dispatch(setLoading(false));
    },
    (error: any) => {
      dispatch(setLoading(false));
      showAlert(2, error.message);
      dispatch(setLoading(false));
    }
  );
};

export const walletTransactions =
  (wallet_id: string, entity_id?: string) =>
    (dispatch: Dispatch<any>, getState: () => RootState) => {
      dispatch(setLoading(true));
      const { page, limit } = getState().user.transactionListingParameters;
      let params = `?page=${page}&limit=${limit}`;
      if (wallet_id) {
        params += `&wallet_id=${wallet_id}`;
      }

      getApiCall(
        EndPoint.walletTransaction + params,
        "",
        (respData: AxiosResponse<any>) => {
          const { data } = respData.data;
          const getTime = (date?: Date) => {
            return date != null ? new Date(date).getTime() : 0;
          };
          let sortedData = data.data.sort((a: any, b: any) => {
            return getTime(b.createdAt) - getTime(a.createdAt);
          });
          const transactionData = {
            result: sortedData,
            page: data.page,
            count: data.count,
          };
          if (entity_id) {
            dispatch(setTransactionForEntity(transactionData));
          } else {
            dispatch(setTransaction(transactionData));
          }
          dispatch(setLoading(false));
        },
        (error: any) => {
          dispatch(setLoading(false));
          showAlert(2, error.message);
          dispatch(setLoading(false));
        }
      );
    };

export const buyEntity = (buyData: any) => (dispatch: Dispatch<any>) => {
  dispatch(setLoading(true));
  postApiCall(
    `${EndPoint.BuyEntity}`,
    buyData,
    (response: AxiosResponse<any>) => {
      if (response.data.statusCode === apiSuccessCode.success) {
        showAlert(1, response.data.data.message);
        dispatch(setLoading(false));
        return;
      }
    },
    (error: any) => {
      showAlert(2, error.data.message);
      dispatch(setLoading(false));
      console.log(error);
    }
  );
};

