/**
 * Paths definitions.
 */
export const Paths = {
  /** Auth Module */

  LOGIN: "/",

  /** Home Module */
  HOME: "/home",
  USER_DASHBOARD: "/user/dashboard",
  USER_ENTITIES: "/user/entities",
  ENTITY_DASHBOARD: "/user/entity/:entityId",
};
