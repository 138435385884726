import { COLORS } from "../../../common/constants/colors";
import { FontFamily } from "../../../common/utils";

const styles = {
  addForm_mainContainer: {
    width: "90%",
    margin: "1rem auto",
    minHeight: "100vh",
  },
  heading: {
    font: FontFamily(700, 25, 30),
    padding: "10px 0 0 0",
    marginBottom: "20px",
  },
  headerRow: { border: `1px solid ${COLORS.black}` },
  tableHeader: {
    "& th": {
      padding: "20px",
      textAlign: "center",
      font: "normal normal 600 18px/22px SofiaPro",
      letterSpacing: "1px",
      color: COLORS.black,
      whiteSpace: "nowrap",
      // border: "1px solid black",
      backgroundColor: "rgb(245 247 253)",
    },
    "& th:nth-last-of-type(1)": { textAlign: "center" },
  },
  table: {
    fontFamily: "SofiaPro",
    width: "95%",
    margin: "0 auto",
    border: "5px solid rgb(245 247 253)",
  },
  row: {
    "& td": {
      textAlign: "center",
      padding: "10px 20px",
      font: FontFamily(500, 14, 18),
      color: COLORS.black,
      // border: "1px solid black",
      textTransform: "Capitalize",
      "& a": { textDecoration: "none", cursor: "pointer" },
      "& span": {
        textTransform: "Uppercase",
        color: COLORS.darkGrey,
      },
    },
  },
  sortIcon: { paddingLeft: "3px", height: "11px", cursor: "pointer" },
  pointer: { cursor: "pointer" },
  headerCell: { textAlign: "center", fontWeight: "bolder", fontSize: "18px" },
};

export default styles;
