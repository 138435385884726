import { Box, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import Schema from "../../common/constants/schema";
import { styles } from "./styles";
import ContainedButton from "../button/contained-button";
import OutlinedButton from "../button/outlined-button";
import SelectComp from "../formControls/select/singleSelect";
import Input from "../formControls/input";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useEffect, useState } from "react";
import {checkBankBalanceBeforeAddFund ,addWalletBalance} from "../../pages/user.dashboard/services/action";
import { AccountsData } from "../../pages/user.dashboard/services/type";
import { useAppSelector } from "../../hooks/useAppSelector";
import { RootState } from "../../redux/store";
interface PropertyPaymentModalType {
  closeModal: () => void;
  walletId: string;
  accountsData: AccountsData[];
  entityId?: string;
}
/**
 * Component for rendering the AddFundsModal.
 */
const AddFundsModal = ({
  closeModal,
  walletId,
  accountsData,
  entityId
}: PropertyPaymentModalType) => {
  const dispatch = useAppDispatch();
  const {
    addBalanceError,
    isCheckingBalance
  } = useAppSelector((state: RootState) => state.user);

  const [addFund, setaddFund] = useState<boolean>(true);
  useEffect(() => {
    if (addBalanceError === "no error") {
      setaddFund(false)
    }
    else {
      setaddFund(true)
    }
  }, [addBalanceError])

  const [linkedBankAccounts, setLinkedBankAccounts] = useState<
    { id: string; value: string }[]
  >([]);
  const [isBankSelected, setIsBankSelected] = useState<boolean>(true);
  useEffect(() => {
    if (accountsData && accountsData.length > 0) {
      accountsData.forEach((bank: any) => {
        const accountId = bank.id;
        if (bank.plaid_account?.accounts.length > 0) {
          bank.plaid_account.accounts.forEach((data: any) => {
            setLinkedBankAccounts((prevLinkedBankAccounts) => [
              ...prevLinkedBankAccounts,
              {
                id: data.id + " " + accountId,
                value: bank.plaid_account?.institution.name + " " + data.name,
              },
            ]);
          });
        }
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const initialValues = {
    amount: "",
    bank: "",
  };
  return (
    <Box sx={styles.modalStyle}>
      <Typography sx={styles.modalHeading}>Transfer Funds</Typography>
      <Typography sx={styles.subHeading}>
        Move funds to your wallet account
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={Schema.AddFundSchema()}
        onSubmit={(values, { setSubmitting, setFieldError }) => {
          const AccountIdandBankId = values.bank.split(" ");
          let dataToSend = {
            wallet_id: walletId,
            bank_id: AccountIdandBankId[1],
            amount: values.amount,
            account_id: AccountIdandBankId[0],
            entity_id: entityId,
          };
            dispatch(addWalletBalance(dataToSend))
          closeModal();
        }}
      >
        {({ setFieldValue, getFieldMeta }) => {
          return (
            <Form>
              <Box sx={styles.formContainer}>
                <SelectComp
                  name="bank"
                  label="Select bank account"
                  options={linkedBankAccounts}
                  labelKey="value"
                  valKey="id"
                  fullWidth
                  onChange={(evt: any) => {
                    const { value } = evt?.target;
                    setIsBankSelected(false)
                    setFieldValue("bank", value);
                  }}
                />

                <Input
                  name="amount"
                  type="number"
                  label="Amount"
                  placeholder="Enter amount"
                  fullWidth
                  isRequired
                  disabled={isBankSelected}
                  externalStyles={styles.textField}
                  onBlur={(evt: any) => {
                    const { value } = evt?.target
                    const data = getFieldMeta("bank");
                    dispatch(checkBankBalanceBeforeAddFund(value, data.value))
                  }}
                  isCheckingBalance={isCheckingBalance}
                />
                {addBalanceError && addBalanceError !== "no error" && <p style={{ color: "#ff1744" }} className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained " id=":r9:-helper-text">Insufficient fund.</p>}
               </Box>

              <Box sx={styles.btnContainer}>
                <ContainedButton
                  title={"Add Fund"}
                  externalStyles={styles.btn}
                  size="large"
                  type="submit"
                  disabled={addFund}
                  fullWidth
                  primary
                />
                <OutlinedButton
                  title="Cancel"
                  externalStyles={styles.btn}
                  size="large"
                  fullWidth
                  onClick={() => {
                    closeModal();
                  }}
                />
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default AddFundsModal;
