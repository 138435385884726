import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { RootState } from "../../../redux/store";
import { getProfile } from "../../login/services/action";
import { setTransactionListing} from "../services/slice";
import {
  getWalletBalance,
  walletTransactions,
} from "../../user.dashboard/services/action";
import { setShowAddFundsModal, setShowWithdrawFundsModel ,setAddBalanceError} from "../../user.dashboard/services/slice";

// Helper Component for WalletDetail Component
const WalletDetailHelper = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [addFund, setaddFund] = useState<boolean>(true);
  const {
    transactionListingParameters,
    accountsData,
    walletDetails,
    transaction,
    entityData
  } = useAppSelector((state: RootState) => state.entity);
  const {
    showAddFundsModel,
    showWithdrawFundsModel
  } = useAppSelector((state: RootState) => state.user);

  const [accountType, setAccountType] = useState("");

  const handleCheckBalModalOpen = () => {
    dispatch(setShowAddFundsModal(true));
  };
  const handleAddFundBtn = () => {
    setaddFund(false)
  };

  const handleCheckBalModalClose = () => {
    dispatch(setShowAddFundsModal(false));
    dispatch(setAddBalanceError(""));
  };

  const handleWithDrawFundsModalOpen = () => {
    dispatch(setShowWithdrawFundsModel(true));
  }

  const handleWithDrawModalClose = () => {
    dispatch(setShowWithdrawFundsModel(false));
  };

  useEffect(() => {
    if (entityData) {
      dispatch(getProfile());
      dispatch(getWalletBalance(entityData.id));
    }
  }, [entityData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (entityData && walletDetails && walletDetails.walletId) {
      dispatch(walletTransactions(walletDetails.walletId, entityData.id));
    }
  }, [walletDetails]) // eslint-disable-line react-hooks/exhaustive-deps
 
  const handlePageChange = (val: any) => {
    if (entityData && walletDetails && walletDetails.walletId) {
      dispatch(
        setTransactionListing({ ...transactionListingParameters, page: val })
      );
      dispatch(walletTransactions(walletDetails.walletId, entityData?.id));
    }
  };


  return {
    navigate,
    dispatch,
    accountsData,
    accountType,
    setAccountType,
    handlePageChange,
    showAddFundsModel,
    walletDetails,
    transaction,
    handleCheckBalModalClose,
    showWithdrawFundsModel,
    handleCheckBalModalOpen,
    entityData,
    handleWithDrawFundsModalOpen,
    handleWithDrawModalClose,
    addFund,
    handleAddFundBtn
  };
};

export default WalletDetailHelper;
