// Import required dependencies from external libraries and local files
import Chip from '@mui/material/Chip';
const ColoredChip = ({ status }: any) => {
    let statusText: string = "";
    switch (status) {
        case 1:
            statusText = "Pending";
            break;
        case 2:
            statusText = "Settled";
            break;
        case 3:
            statusText = "Posted";
            break;
        case 4:
            statusText = "Cancelled ";
            break;
        case 5:
            statusText = "Failed";
            break;
        case 6 :
            statusText = "Returned";
            break;

        default:
            statusText = "--";;
            break;
    }
    return (
        <Chip label={statusText} />
    );
};

// Export the OutlinedButton component as the default export
export default ColoredChip;
