import * as Yup from "yup";

/**
 * Schema definitions.
 */
const LoginSchema = () =>
  Yup.object().shape({
    email: Yup.string()
      .trim()
      .required("Please enter the required field")
      .email("Please enter a valid email address"),
    password: Yup.string()
      .trim()
      .required("Please enter the required field")
      .min(8, "Password must be atleast 8 characters long")
      .max(15, "Maximum 15 characters are allowed"),
  });

const VerifyOtpSchema = () =>
  Yup.object().shape({
    otp: Yup.string()
      .min(4, "Please enter valid OTP")
      .required("Please enter OTP"),
  });

const RegisterSchema = () =>
  Yup.object().shape({
    firstname: Yup.string()
      .trim()
      .matches(/^[a-zA-Z ]*$/, "Only characters are allowed")
      .min(3, "First Name should be atleast 3 characters long")
      .max(40, "Maximum 40 characters are allowed")
      .required("Please enter your First Name"),
    lastname: Yup.string()
      .trim()
      .matches(/^[a-zA-Z ]*$/, "Only characters are allowed")
      .min(3, "Last Name should be atleast 3 characters long")
      .max(40, "Maximum 40 characters are allowed")
      .required("Please enter your Last Name"),
    email: Yup.string()
      .trim()
      .email("Please enter valid Email")
      .required("Please enter your Email")
      .max(40, "Maximum 40 characters are allowed"),
    phoneNo: Yup.string()
      .trim()
      .matches(/^\d*$/, "Only Numbers are allowed")
      .min(8, "Mobile number should be atleast 8 digits long")
      .max(10, "Maximum 10-digits are allowed")
      .required("Please enter your Mobile Number"),
    password: Yup.string()
      .trim()
      .min(8, "Password must be atleast 8 characters long")
      .max(15, "Maximum 15 characters are allowed")
      .required("Please enter Password"),
    code: Yup.string().trim(),
  });

const ForgetPasswordSchema = () =>
  Yup.object().shape({
    email: Yup.string()
      .trim()
      .email("Please enter valid Email")
      .required("Please fill in the required field"),
  });

const ResetPasswordSchema = () =>
  Yup.object().shape({
    password: Yup.string()
      .trim()
      .min(8, "Password must be atleast 8 characters long")
      .max(15, "Maximum 15 characters are allowed")
      .required("Please fill in the required field"),
    cnfPassword: Yup.string()
      .trim()
      .required("Please fill in the required field")
      .when("password", {
        is: (val: any) => val && val.length > 0,
        then: (schema) =>
          schema.oneOf(
            [Yup.ref("password")],
            "Confirm Password does not match"
          ),
      }),
  });

const CreateCustodialSchema = () =>
  Yup.object().shape({
    accountName: Yup.string()
      .trim()
      .required("Please fill in the required field"),
    DOB: Yup.string().trim().required("Please fill in the required field"),
    taxIdNumber: Yup.string()
      .trim()
      .required("Please fill in the required field")
      .max(9, "Tax ID Number should be of length 9")
      .min(9, "Tax ID Number should be of length 9"),
    street1: Yup.string().trim().required("Please fill in the required field"),
    street2: Yup.string().trim().required("Please fill in the required field"),
    postalCode: Yup.string()
      .trim()
      .required("Please fill in the required field"),
    city: Yup.string().trim().required("Please fill in the required field"),
    region: Yup.string().trim().required("Please fill in the required field"),
    contactEmail: Yup.string()
      .trim()
      .required("Please fill in the required field")
      .email("Please enter valid Email"),
  });

const CreateCustodialFortressSchema = () =>
  Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .required("Please fill in the required field"),
    lastName: Yup.string().trim().required("Please fill in the required field"),
    phone: Yup.string()
      .trim()
      .matches(/^\d*$/, "Only Numbers are allowed")
      .min(8, "Mobile number should be atleast 8 digits long")
      .max(10, "Maximum 10-digits are allowed")
      .required("Please enter your Mobile Number"),
    email: Yup.string()
      .trim()
      .required("Please fill in the required field")
      .email("Please enter valid Email"),
    dateOfBirth: Yup.string()
      .trim()
      .required("Please fill in the required field"),
    ssn: Yup.string()
      .trim()
      .required("Please fill in the required field")
      .max(12, "Tax ID Number should have a minimum length of 9")
      .min(9, "SSn Number should have a maximum length of 12"),
    street1: Yup.string().trim().required("Please fill in the required field"),
    street2: Yup.string().trim().required("Please fill in the required field"),
    postalCode: Yup.string()
      .trim()
      .required("Please fill in the required field"),
    city: Yup.string().trim().required("Please fill in the required field"),
    state: Yup.string().trim().required("Please fill in the required field"),
    country: Yup.string().trim().required("Please fill in the required field"),
  });

const CompleteYourKYC = () =>
  Yup.object().shape({
    doc_type: Yup.string().required("Please fill in the required field"),
    front: Yup.string(),
    back: Yup.string(),
  });

const CompleteYourFortressKYC = () =>
  Yup.object().shape({
    doc_type: Yup.string().required("Please fill in the required field"),
    front: Yup.string().required("Please Upload the reuired document"),
    back: Yup.string().required("Please Upload the reuired document"),
    self: Yup.string(),
  });

const FortressBusinessID = () =>
  Yup.object().shape({
    companyName: Yup.string().required("Please fill in the required field"),
    ein: Yup.string(),
    website: Yup.string(),
    phoneNumber: Yup.string().required("Please fill in the required field"),
    email: Yup.string()
      .required("Please fill in the required field")
      .email("Please enter valid Email"),
    street1: Yup.string().required("Please fill in the required field"),
    street2: Yup.string().required("Please fill in the required field"),
    postalCode: Yup.string().required("Please fill in the required field"),
    city: Yup.string().required("Please fill in the required field"),
    state: Yup.string().required("Please fill in the required field"),
    country: Yup.string().required("Please fill in the required field"),
    regionOfFormation: Yup.string().required(
      "Please fill in the required field"
    ),
    description: Yup.string().required("Please fill in the required field"),
    establishedOn: Yup.string().required("Please fill in the required field"),
    legalStructure: Yup.string().required("Please fill in the required field"),
    naics: Yup.string(),
    naicsDescription: Yup.string(),
    mstreet1: Yup.string().required("Please fill in the required field"),
    mstreet2: Yup.string().required("Please fill in the required field"),
    mpostalCode: Yup.string().required("Please fill in the required field"),
    mcity: Yup.string().required("Please fill in the required field"),
    mstate: Yup.string().required("Please fill in the required field"),
    mcountry: Yup.string().required("Please fill in the required field"),
    beneficialOwners: Yup.array().test({
      message: "Please add beneficial owners",
      test: (arr) => arr?.length !== 0,
    }),
  });

const CreateWalletSchema = () => {
  return Yup.object().shape({
    walletName: Yup.string().required("Please fill in the required field"),
    assets: Yup.array().of(
      Yup.object().shape({
        chain: Yup.string().required("Please fill in the required field"),
        assetType: Yup.string().required("Please fill in the required field"),
      })
    ),
  });
};

const WalletAddresess = () => {
  return Yup.object().shape({
    chain: Yup.string().required("Please fill in the required field"),
    assetType: Yup.string().required("Please fill in the required field"),
  });
};

const AddCardSchema = () =>
  Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .required("Please fill in the required field"),
    lastName: Yup.string().trim().required("Please fill in the required field"),
    street1: Yup.string().trim().required("Please fill in the required field"),
    street2: Yup.string().trim().required("Please fill in the required field"),
    postalCode: Yup.string()
      .trim()
      .required("Please fill in the required field"),
    city: Yup.string().trim().required("Please fill in the required field"),
    state: Yup.string().trim().required("Please fill in the required field"),
    country: Yup.string().trim().required("Please fill in the required field"),
    number: Yup.string()
      .trim()
      // .max(16, "Please enter a valid value")
      // .min(16, "Please enter a valid value")
      .required("Please fill in the required field"),
    expirationMonth: Yup.string().required("Please fill in the required field"),
    expirationYear: Yup.string().required("Please fill in the required field"),
    cvv: Yup.string()
      .trim()
      .max(3, "Please enter a valid value")
      .min(3, "Please enter a valid value")
      .required("Please fill in the required field"),
  });

const CardPaymentSchema = () =>
  Yup.object().shape({
    funds: Yup.string().required("Please fill in the required field"),
    comment: Yup.string().required("Please fill in the required field"),
  });

const TransferSchema = () =>
  Yup.object().shape({
    selectedId: Yup.string().required("Please fill in the required field"),
    funds: Yup.string().required("Please fill in the required field"),
    comment: Yup.string().required("Please fill in the required field"),
  });

const PropertySellSchema = () =>
  Yup.object().shape({
    tokenValue: Yup.string().required("Please fill in the required field"),
    noOfTokenToSell: Yup.string().required("Please fill in the required field"),
  });

const PropertyBuySchema = (dynamicValue: number) =>
  Yup.object().shape({
    noOfTokenToBuy: Yup.number()
      .max(dynamicValue, `Value must be less than ${dynamicValue}`)
      .required("Please fill in the required field"),
  });

const FundPaymentSchema = () =>
  Yup.object().shape({
    mode: Yup.string().required("Please fill in the required field"),
    fund: Yup.string().required("Please fill in the required field"),
    comment: Yup.string(),
  });
  const AddFundSchema = () =>
  Yup.object().shape({
    bank: Yup.string().required("Please select bank account."),
    amount: Yup.number().min(1).required("Please enter amount."),
  });
const Schema = {
  LoginSchema,
  VerifyOtpSchema,
  RegisterSchema,
  ForgetPasswordSchema,
  ResetPasswordSchema,
  CreateCustodialSchema,
  CreateCustodialFortressSchema,
  CompleteYourKYC,
  CompleteYourFortressKYC,
  FortressBusinessID,
  CreateWalletSchema,
  WalletAddresess,
  AddCardSchema,
  CardPaymentSchema,
  TransferSchema,
  PropertySellSchema,
  PropertyBuySchema,
  FundPaymentSchema,
  AddFundSchema
};

export default Schema;
