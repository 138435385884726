import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { RootState } from "../../redux/store";
import { getUserEntities } from "./services/action";

// Helper Component for User's Entites Component
export const useUserEntitesHelper = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const name = localStorage.getItem("name") || "";
  const { userDetails } = useAppSelector((state: RootState) => state.login);
  const { loading } = useAppSelector((state: RootState) => state.global);
  const { userEntities } = useAppSelector((state: RootState) => state.entities);

  useEffect(() => {
    dispatch(getUserEntities());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    name,
    navigate,
    loading,
    dispatch,
    userDetails,
    userEntities,
  };
};
