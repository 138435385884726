// This file defines a Redux slice for managing the home state.

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {

  UserEntityModel,
} from "./type";
import { Entity } from "../../user.dashboard/services/type";
// Initial state for the user slice
const initialState: UserEntityModel = {
  userEntities: []
};

// Create the userEntities slice using createSlice from Redux Toolkit
const userSlice = createSlice({
  name: "entities",
  initialState,
  reducers: {
    setUserEntities: (state: UserEntityModel, action: PayloadAction<Entity[]>) => {
      state.userEntities = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setUserEntities,
} = userSlice.actions;

export default userSlice.reducer;
