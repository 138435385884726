import { combineReducers } from "@reduxjs/toolkit";

import GlobalSlice from "./global.slice";
import UserSlice from "../pages/user.dashboard/services/slice";
import LoginSLice from "../pages/login/services/slice";
import FundSlice from "../pages/plaid-link/services/slice";
import UserEntitiesSlice from "../pages/user.entities/services/slice";
import EntityDashabordSlice from "../pages/entity.dashboard/services/slice";

/**
 * This file defines the root reducer for the Redux store.
 * It combines multiple reducers into a single reducer.
 */

// Combine individual reducers into the root reducer
const rootReducer = combineReducers({
  global: GlobalSlice,
  user: UserSlice,
  login: LoginSLice,
  entities: UserEntitiesSlice,
  entity: EntityDashabordSlice,
  fund: FundSlice,
});

export default rootReducer;
