import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { RootState } from "../../../redux/store";
import { setShowUnlinkModal } from "../services/slice";
import { getPlaidAccountsForEntity } from "../services/action";

// Helper Component for Linked accounts data
const useAccountsHelper = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { entityData, accountsData, showUnlinkBankModal } = useAppSelector(
    (state: RootState) => state.entity
  );

  const { loading } = useAppSelector((state: RootState) => state.global);

  const closeConfirmModal = () => {
    dispatch(setShowUnlinkModal(false));
  };

  const openConfirmModal = () => {
    dispatch(setShowUnlinkModal(true));
  };

  useEffect(() => {
    if (entityData) {
      dispatch(getPlaidAccountsForEntity(entityData.id));
    }
  }, [entityData]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    navigate,
    dispatch,
    accountsData,
    loading,
    showUnlinkBankModal,
    openConfirmModal,
    closeConfirmModal,
    entityData,
  };
};

export default useAccountsHelper;
