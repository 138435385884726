/**
 * API endpoint paths.
 */
export const EndPoint = {
  /** Onboarding */
  login: "/user/login",
  getProfile: "/user/profile",
  checkBalance:"/user/balance/get",

  /** User  */
  CreateLinkToken: "/user/link/token/create",
  PublicTokenExchange: "/user/public/token/exchange",
  GetPlaidAccounts: "/user/accounts/get",
  UnlinkBankAccount: "/user/unlink/bank",
  JwtTokenClient: "/user/authToken",
  CreateProcessorToken: "/user/processor/token/create",


  /** Entities  */
  GetAllEntities: "/user/all/entities",
  GetUserEntities: "/user/entity",
  GetEntityDetails: "/user/entity/details",

  /** Wallet */
  GetWalletBalance: "/user/wallet/get",
  addWalletBalance: "/user/wallet/add/fund",
  withdrawWalletBalance: "/user/wallet/withdraw/fund",
  walletTransaction: "/user/wallet/transaction/history",
  BuyEntity: "/user/wallet/buy",
};
