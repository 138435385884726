import { AxiosResponse } from "axios";
import { Dispatch } from "@reduxjs/toolkit";

import { setLoading } from "../../../redux/global.slice";
import { showAlert } from "../../../common/utils/alert";
import { EndPoint, postApiCall } from "../../../common/constants";
import { apiSuccessCode } from "../../../common/constants/axios.instance";
import { setLinkToken } from "./slice";
import { getPlaidAccounts } from "../../user.dashboard/services/action";
import { getPlaidAccountsForEntity } from "../../entity.dashboard/services/action";


// Action to public token
export const PublicTokenExchange =
  (token: string, metaData: any, entity_id?: string) =>
  (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));

    const dataToSend = { publicToken: token, metadata: metaData, entity_id };

    postApiCall(
      EndPoint.PublicTokenExchange,
      dataToSend,
      (respData: AxiosResponse<any>) => {
        if (respData.data.httpCode === apiSuccessCode.success) {
          showAlert(1, respData.data.message);
        }
        if (entity_id) {
          dispatch(getPlaidAccountsForEntity(entity_id));
        } else {
          dispatch(getPlaidAccounts());
        }
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };

// Action to create link token
export const createLinkToken = () => (dispatch: Dispatch<any>) => {
  dispatch(setLoading(true));

  const dataToSend = { countryCodes: ["US"] };
  postApiCall(
    EndPoint.CreateLinkToken,
    dataToSend,
    (respData: AxiosResponse<any>) => {
      console.log(respData);
      if (respData.data.statusCode === apiSuccessCode.success) {
        const { data } = respData.data;

        dispatch(setLinkToken(data.link_token));
      }
      dispatch(setLoading(false));
    },
    (error: any) => {
      dispatch(setLoading(false));
      showAlert(2, error.message);
    }
  );
};
