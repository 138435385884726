/**
 * Function for generating CSS font property value based on provided parameters.
 * @param {number} fontWeight - The font weight.
 * @param {number|string} fontSize - The font size.
 * @param {number|string} lineHeight - The line height.
 * @param {string} fontStyle - The font style (optional, defaults to "normal").
 * @param {string} fontFamily - The font family (optional, defaults to "SofiaPro").
 * @returns {string} The CSS font property value.
 */
export const FontFamily = (
  fontWeight: number,
  fontSize: number | string,
  lineHeight: number | string,
  fontStyle = "normal",
  fontFamily = `SofiaPro`
) => {
  let fontsize = "";
  let lineheight = "";

  if (typeof fontSize === "string") fontsize = fontSize;
  else fontsize = `${fontSize}px`;

  if (typeof lineHeight === "string") lineheight = lineHeight;
  else lineheight = `${lineHeight}px`;

  return `${fontStyle} ${fontWeight} ${fontsize}/${lineheight} ${fontFamily}`;
};

/**
 * Function for logging out the user.
 * It performs the necessary steps to clear local storage.
 */
export const logout = () => {
  localStorage.clear();
  window.location.reload();
};
