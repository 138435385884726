import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { RootState } from "../../../redux/store";
import { getProfile } from "../../login/services/action";
import { setTransactionListing, setShowAddFundsModal, setShowWithdrawFundsModel ,setAddBalanceError} from "../services/slice";
import {
  getWalletBalance,
  walletTransactions,
} from "../../user.dashboard/services/action";

// Helper Component for WalletDetail Component
const WalletDetailHelper = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    transactionListingParameters,
    accountsData,
    showAddFundsModel,
    showWithdrawFundsModel,
    walletDetails,
    transaction
  } = useAppSelector((state: RootState) => state.user);
  const [accountType, setAccountType] = useState("");
  const handleCheckBalModalOpen = () => {
    dispatch(setShowAddFundsModal(true));
  };
  const handleCheckBalModalClose = () => {
    dispatch(setShowAddFundsModal(false));
    dispatch(setAddBalanceError(""));
  };

  const handleWithDrawFundsModalOpen = () => {
    dispatch(setShowWithdrawFundsModel(true));
  }

  const handleWithDrawModalClose = () => {
    dispatch(setShowWithdrawFundsModel(false));
  };

  useEffect(() => {
    dispatch(getProfile());
    dispatch(getWalletBalance());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (walletDetails && walletDetails.walletId ) {
      dispatch(walletTransactions(walletDetails.walletId));
    }
  }, [walletDetails])// eslint-disable-line react-hooks/exhaustive-deps

  const handlePageChange = (val: any) => {
    if (walletDetails && walletDetails.walletId) {
      dispatch(
        setTransactionListing({ ...transactionListingParameters, page: val })
      );
      dispatch(walletTransactions(walletDetails.walletId));
    }
  };

  return {
    navigate,
    dispatch,
    accountsData,
    accountType,
    setAccountType,
    handlePageChange,
    showAddFundsModel,
    showWithdrawFundsModel,
    walletDetails,
    transaction,
    handleCheckBalModalClose,
    handleCheckBalModalOpen,
    handleWithDrawFundsModalOpen,
    handleWithDrawModalClose
  };
};

export default WalletDetailHelper;
