import { Navigate } from "react-router-dom";
import { getAccessToken } from "../common/utils";

/**
 * This component is used to create a public route that is accessible to all users.
 * It renders the specified component for the given route path.
 */
const PublicRoute = ({ children }: any) => {
  return getAccessToken() ? <Navigate to="/user/dashboard" /> : children;
  // return <Navigate to="/home" />;

};
export default PublicRoute;
