import Pagination from "@mui/material/Pagination";
import { Box, Typography } from "@mui/material";
import { PaginationProps } from "./pagination.types";
import styles from "./pagination.styles";

// Defines the Pagination Section for Tables
const PaginationSection = ({ listData, handleChangePage }: PaginationProps) => {
  const totalRecords = listData?.count || 0;
  const totalPages = Math.ceil(totalRecords / 10) || 1;
  const lowerLimit = 10 * ((listData?.page || 1) - 1) + 1;
  const upperLimit =
    listData?.data?.length === 10
      ? listData.page * 10
      : lowerLimit + ((listData?.data?.length || 10) - 1);

  return (
    <Box sx={styles.paginationContainer}>
      <Box sx={styles.countContainer}>
        <Typography
          sx={styles.count}
        >{`Showing ${lowerLimit} to ${upperLimit} of ${totalRecords} enteries`}</Typography>
      </Box>
      <Box sx={styles.pageContainer}>
        <Typography
          onClick={() => {
            if (listData?.page !== 1) {
              handleChangePage((listData?.page || 1) - 1);
            }
          }}
          onKeyPress={(event: any) => {
            if (event.key === "Enter" && listData?.page !== 1) {
              handleChangePage((listData?.page || 1) - 1);
            }
          }}
          sx={{
            ...styles.previous,
            ...(listData?.page === 1 && { ...styles.disabled }),
          }}
          tabIndex={0}
        >
          Previous
        </Typography>
        <Pagination
          shape="rounded"
          sx={styles.pageBlock}
          count={totalPages}
          hideNextButton
          hidePrevButton
          page={listData?.page || 1}
          onChange={(e, val) => {
            handleChangePage(val);
          }}
        />
        <Typography
          onClick={() => {
            if (totalPages !== listData?.page) {
              handleChangePage((listData?.page || 1) + 1);
            }
          }}
          onKeyPress={(event: any) => {
            if (event.key === "Enter" && totalPages !== listData?.page) {
              handleChangePage((listData?.page || 1) + 1);
            }
          }}
          sx={{
            ...styles.next,
            ...(totalPages === listData?.page && { ...styles.disabled }),
          }}
          tabIndex={0}
        >
          Next
        </Typography>
      </Box>
    </Box>
  );
};

export default PaginationSection;
