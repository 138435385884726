import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Paths } from "../common/constants/paths";
import AuthWrapper from "../components/layout/onboarding-wrapper";
import PublicWrapper from "../components/layout/public-wrapper";

import Login from "../pages/login";

import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import UserDashboard from "../pages/user.dashboard";
import UserEntities from "../pages/user.entities";
import EntityDashboard from "../pages/entity.dashboard";

/**
 * This component manages the routing configuration for the application.
 * It defines the routes and specifies which component to render for each route.
 */
const RoutesManager = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={Paths.LOGIN}
          element={
            <AuthWrapper
              childComp={
                <PublicRoute>
                  <Login />
                </PublicRoute>
              }
            />
          }
        />

        <Route
          path={Paths.USER_DASHBOARD}
          element={
            <PrivateRoute>
              <PublicWrapper childComp={<UserDashboard />} />
            </PrivateRoute>
          }
        />
        <Route
          path={Paths.USER_ENTITIES}
          element={
            <PrivateRoute>
              <PublicWrapper childComp={<UserEntities />} />
            </PrivateRoute>
          }
        />
        <Route
          path={`${Paths.ENTITY_DASHBOARD}`}
          element={
            <PrivateRoute>
              <PublicWrapper childComp={<EntityDashboard />} />
            </PrivateRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default RoutesManager;
