import { Navigate } from "react-router-dom";
import { getAccessToken } from "../common/utils";

/**
 * This component is used to create a private route that requires authentication.
 * It checks if the user is authenticated and renders the component if true,
 * otherwise, it redirects the user to a login page.
 */
const PrivateRoute = ({ children }: any) => {
  return getAccessToken() ? children : <Navigate to="/" />;
  // return children

};
export default PrivateRoute;
